export const abuseNames = {
    phishing: 'Phishing',
    malware: 'Malware',
    botnets: 'Botnets',
    spam: 'Spam',
    smishing: 'Smishing',

    // csam: 'Child Sexual Abuse Material',
    // sms: 'SMS Spam',
    // social: 'Social Media Post',
    // test: 'Test',
    
};
