import { okUrl, okEmail } from '../utils/ok';

const FIELD_DATE = {
    id: 'date', label: 'Date of incident', title: 'The date on which you encounter this harm.', fields: [
        { field: 'date', label: "Date", input: 'text', type: 'date', ongoing: false, okay: (v) => v?.date?.match(/^\d\d\d\d-(?:0\d|1[012])-(?:[012]\d|3[01])$/), description: "The date on which you encounter this harm." },
    ]
};

const FIELD_GEO = {
    id: 'geo', label: 'Your location', title: 'Some harms are restricted to particular places, it can be helfpful to know where it was accessible from.', fields: [
        { field: 'geo', label: "Location", optional: true, input: 'text', type: 'geo', /*okay: (v) => v && v.length > 0,*/ description: "Where it was accessible from." },
    ]
};

const FIELD_ATTACHMENTS_1 = {
    id: 'attachments', label: 'Additional Evidence', title: 'Share any files or screenshots that might help an investigation.', fields: [
        { field: 'attachments', label: "Evidence", optional: true, input: 'file', multi: true, description: "Any files or screenshots that might help an investigation." },
    ]
};

const FIELD_ATTACHMENTS_2 = {
    id: 'attachments', label: 'Additional evidence', title: 'Share any files or screenshots that might help an investigation. Screenshots are helpful for phishes sent via text or instant message.', fields: [
        { field: 'attachments', label: "Evidence", optional: true, input: 'file', multi: true, description: "Any files or sreenshots that might help an investigation." },
    ]
};

const FIELD_MESSAGE = {
    id: 'message', label: 'Email headers and body', title: 'Email headers are useful in accurately identifying who sent spam. It can be difficult or impossible to act without these. You can find out how to get them by selecting your email platform here: https://mxtoolbox.com/public/content/emailheaders/', fields: [
        { field: 'headers', label: "Email message headers", input: 'textarea', optional: true, description: "The email message headers." },
        { field: 'body', label: "Email message body", input: 'textarea', optional: true, description: "The email message body." },
    ]
};

const FIELD_DESCRIPTION = {
    id: 'description', label: 'What happened?', title: 'Provide a brief description of the issue.', fields: [
        { field: 'description', label: "Description", input: 'textarea', okay: (desc) => desc.length > 0, description: "A brief description of the issue." },
    ]
};

export const abuseSteps = {
    spam: [
        // {
        //     id: 'urls', label: 'Abusive Web Sites', title: 'Provide the suspected suspicious web sites (URLs) from the spam message.', fields: [
        //         { field: 'urls', label: "URL", input: 'text', type: 'url', multi: true, okay: (u) => okUrl(u), description: "the suspected suspicious web sites (URLs) from the spam message" },
        //     ]
        // },
        FIELD_DATE,
        FIELD_DESCRIPTION,
        FIELD_ATTACHMENTS_2,
        FIELD_GEO,
        {
            id: 'email', label: "Sender email", title: 'The sender\'s email address.', fields: [
                { field: 'email', label: "Email Address", input: 'text', type: 'email', okay: (e) => okEmail(e), description: "The sender's email address." },
            ]
        },
        FIELD_MESSAGE,
    ],
    malware: [
        // {
        //     id: 'url', label: 'Abusive Web Site', title: 'Provide the web site (URL) delivering the malicious software.', fields: [
        //         { field: 'url', label: "URL", input: 'text', type: 'url', okay: (u) => okUrl(u), description: "the web site (URL) delivering the malicious software" },
        //     ]
        // },
        FIELD_DATE,
        {
            id: 'filename', label: 'Malicious file name', title: "The name of the malicious file, for e.g. 'malware.exe'", fields: [
                { field: 'filename', label: 'Malicious file name', input: 'text', type: 'text', okay: (u) => u.length > 0, description: "The name of the malicious file." },
            ]
        },
        FIELD_DESCRIPTION,
        FIELD_ATTACHMENTS_1,
        FIELD_GEO,
        {
            id: 'details', label: 'Technical details', title: 'Any other technical details about the software or file.', fields: [
                { field: 'md5', label: 'MD5 checksum of file', input: 'text', type: 'text', optional: true, okay: (h) => h.length === 0 || h.match(/^[a-fA-F\d]{32}$/), description: "The MD5 checksum of the file" },
                { field: 'sha1', label: 'SHA1 checksum of file', input: 'text', type: 'text', optional: true, okay: (h) => h.length === 0 || h.match(/^[a-fA-F\d]{40}$/), description: "The SHA1 checksum of the file" },
            ]
        },
    ],
    botnets: [
        // {
        //     id: 'url', label: 'Abusive Web Site', title: 'Provide the sources of the botnet attack.', fields: [
        //         { field: 'url', label: "URL", input: 'text', type: 'url', multi: true, okay: (u) => okUrl(u), description: "the web sites (URLs) behind the botnet attack" },
        //         // { field: 'url', label: "URL", input: 'text', type: 'url', optionalWhen: (data) => !data.ip, okay: (u, r) => okUrl(u) || (r && okIp(r.ip || '') && okPort(r.port || '')), description: "the web site (URL) behind the botnet attack", extra: " (if IP address not provided)" },
        //         // { field: 'ip', label: "IP Address", input: 'text', optionalWhen: (data) => !data.url, pattern: /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/, okay: (i, r) => okIp(i) || (r && okUrl(r.url || '')), description: "the IP address behind the botnet attack", extra: " (if URL not provided)" },
        //         // { field: 'port', label: "Port", input: 'text', type: 'number', optionalWhen: (data) => !data.url, okay: (p, r) => okPort(p) || (r && okUrl(r.url || '')), description: "the port on the IP address behind botnet attack", extra: " (if the IP address is provided)"},
        //     ]
        // },
        FIELD_DATE,
        {
            id: 'botnet', label: 'Name of Botnet', title: 'The name of the botnet.', fields: [
                { field: 'botnet', label: "Botnet Name", input: 'text', optional: true, okay: (v) => v === '' || v.length > 0, description: "The name of the botnet." },
            ]
        },
        FIELD_DESCRIPTION,
        FIELD_ATTACHMENTS_1,
        FIELD_GEO,
    ],
    phishing: [
        // {
        //     id: 'url', label: 'Abusive Web Site', title: 'Provide the web site (URL) requesting personal information.', fields: [
        //         { field: 'url', label: "URL", input: 'text', type: 'url', okay: (u) => okUrl(u), description: "the web site (URL) requesting personal information" },
        //     ]
        // },
        FIELD_DATE,
        {
            id: 'target', label: 'Institution targeted', title: 'The name of the company or organization being impersonated.', fields: [
                { field: 'target', label: "Institution targeted", input: 'text', type: 'text', okay: (v) => v.length > 0, description: "The name of the company or organization being impersonated." },
                { field: 'targetUrl', label: "Address of impersonated website", input: 'text', type: 'text', optional: true, description: "The website of the company or organization being impersonated." },
            ]
        },
        FIELD_DESCRIPTION,
        FIELD_ATTACHMENTS_2,
        FIELD_GEO,
        {
            id: 'email', label: "Sender Email", title: "If the phish was sent via email, the sender's email address.", fields: [
                { field: 'email', label: "Email Address", input: 'text', type: 'email', optional: true, okay: (e) => e.length === 0 || okEmail(e), description: "If the phish was sent via email, the sender's email address." },
            ]
        },
        FIELD_MESSAGE,
    ],
    smishing: [
        FIELD_DATE,
        {
            id: 'target', label: 'Institution targeted', title: 'The name of the company or organization being impersonated.', fields: [
                { field: 'target', label: "Institution targeted", input: 'text', type: 'text', okay: (v) => v.length > 0, description: "The name of the company or organization being impersonated." },
                { field: 'targetUrl', label: "Address of impersonated website", input: 'text', type: 'text', optional: true, description: "The website of the company or organization being impersonated." },
            ]
        },
        FIELD_DESCRIPTION,
        FIELD_ATTACHMENTS_2,
        FIELD_GEO,
        {
            id: 'email', label: "Sender Email", title: "If the smish was sent via email, the sender's email address.", fields: [
                { field: 'email', label: "Email Address", input: 'text', type: 'email', optional: true, okay: (e) => e.length === 0 || okEmail(e), description: "If the smish was sent via email, the sender's email address." },
            ]
        },
        // FIELD_MESSAGE,
        {
            id: 'message', label: 'Message Text', title: 'Provide the full message body.', fields: [
                { field: 'body', label: "Message Text", input: 'textarea', optional: true, okay: (body) => body.length > 0, description: "The full message body." },
            ]
        },
    ],
    csam: [
        // {
        //     id: 'urls', label: 'Abusive Web Sites', title: 'Provide the web sites (URLs) providing explicit materials.', fields: [
        //         { field: 'urls', label: "URL", input: 'text', type: 'url', multi: true, okay: (u) => okUrl(u), description: "the web sites (URLs) providing explicit materials" },
        //     ]
        // },
        FIELD_DATE,
        FIELD_GEO,
    ],
    sms: [
        // {
        //     id: 'urls', label: 'Abusive Web Sites', title: 'Provide the suspected suspicious web sites (URLs) from the text message.', fields: [
        //         { field: 'urls', label: "URL", input: 'text', type: 'url', multi: true, okay: (u) => okUrl(u), description: "the suspected suspicious web sites (URLs) from the text message" },
        //     ]
        // },
        FIELD_DATE,
        FIELD_GEO,
        {
            id: 'email', label: "Spammer's Number", title: 'Provide the number that sent the text message.', fields: [
                { field: 'email', label: "Sender", input: 'text', type: 'text', okay: (v) => v.length > 0, description: "the number that sent the text message" },
            ]
        },
        {
            id: 'message', label: 'Message Text', title: 'Provide the full message body.', fields: [
                { field: 'body', label: "Message Text", input: 'textarea', okay: (body) => body.length > 0, description: "the full message body" },
            ]
        },
        {
            id: 'description', label: 'What Happened?', title: 'Provide a brief description of the abuse event.', fields: [
                { field: 'description', label: "Description", input: 'textarea', okay: (desc) => desc.length > 0, description: "a brief description of the abuse event" },
            ]
        },
        {
            id: 'attachments', label: 'Additional Evidence', title: 'Share any files (e.g. screenshots) that might help.', fields: [
                { field: 'attachments', label: "Evidence", optional: false, input: 'file', multi: true, description: "any files that can serve as additional evidence of the abuse" },
            ]
        },
    ],
    social: [
        {
            id: 'link', label: 'Social Media Post', title: 'Provide the web site (URL) of the social media post.', fields: [
                { field: 'link', label: "URL", input: 'text', type: 'url', okay: (u) => okUrl(u), description: "the URL of the social media post" },
            ]
        },
        // {
        //     id: 'urls', label: 'Abusive Web Sites', title: 'Provide the suspected suspicious web sites (URLs) from the social media post.', fields: [
        //         { field: 'urls', label: "URL", input: 'text', type: 'url', multi: true, okay: (u) => okUrl(u), description: "the suspected suspicious web sites (URLs) from the social media post" },
        //     ]
        // },
        FIELD_DATE,
        FIELD_GEO,
        {
            id: 'message', label: 'Post Contents', title: 'Provide the full body of the social media post.', fields: [
                { field: 'body', label: "Message Text", input: 'textarea', okay: (body) => body.length > 0, description: "the full body of the social media post" },
            ]
        },
        {
            id: 'description', label: 'What Happened?', title: 'Provide a brief description of the abuse event.', fields: [
                { field: 'description', label: "Description", input: 'textarea', okay: (desc) => desc.length > 0, description: "a brief description of the abuse event" },
            ]
        },
        {
            id: 'attachments', label: 'Additional Evidence', title: 'Share any files (e.g. screenshots) that might help.', fields: [
                { field: 'attachments', label: "Evidence", optional: false, input: 'file', multi: true, description: "any files that can serve as additional evidence of the abuse" },
            ]
        },
    ],
    test: [
        {
            id: 'attachments', label: 'Additional Evidence', title: 'Share any files (e.g. screenshots) that might help.', fields: [
                { field: 'attachments', label: "Evidence", optional: false, input: 'file', multi: true, description: "any files that can serve as additional evidence of the abuse" },
            ]
        },
    ]
};
