import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RealmContext } from '../ReactRealmProvider';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Button, FormControlLabel, Grid, IconButton, ImageList, ImageListItem, Paper, Switch as Toggle, Table, TableBody, TableCell, TableContainer, TableRow, TextField, ToggleButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BreakPointCard } from '../components/BreakPointCard';
import { AbuseReportSummary } from '../components/AbuseReportSummary';
import { useUserRole } from '../hooks/useUserRole';
import { injectBreakPoints } from '../utils/injectBreakPoints';
import { sorters } from '../data/sorters';
import { downloadEvidence } from '../utils/downloadEvidence';
import { DataGrid } from '@mui/x-data-grid';
import { BSON } from 'realm-web';
import { abuseNames } from '../data/abuseNames';
import { Check, Flag, FlagOutlined } from '@mui/icons-material';
import { DateTime } from '../components/DateTime';

const columns = [
    { field: 'score', headerName: " ", width: 60, renderCell: (params) => <>
        {/* <IconButton onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            params.row.updateReport(params.row.id, 'flagged', !params.row.flagged);
        }}> */}
            {params.row.score ? <Flag color={params.row.score < 0 ? 'error' : 'success'}/> : <FlagOutlined sx={{opacity: .25}}/>}
        {/* </IconButton> */}
        </>
    },
    { field: 'showId', headerName: 'ID', width: 80 },
    { field: 'type', headerName: 'Abuse Type', width: 110, valueGetter: (params) => abuseNames[params.row.type] },
    { field: 'tsReported', headerName: 'Date Reported', width: 200, renderCell: (params) => <DateTime timestamp={params.row.tsReported} /> },
    { field: 'reporter', headerName: 'Reported By', width: 240, valueGetter: (params) => params.row.reporter?.email },
    { field: 'url', headerName: 'URL(s)', width: 450 },
    // { field: 'date.date', headerName: 'Abuse Date', width: 240, valueGetter: (params) => params.row.date.date, valueFormatter: ({ value }) => value.toLocaleString() },
];

export const Reports = () => {
    const { user, mongo } = useContext(RealmContext);
    const isAdmin = user.customData.type === 'admin';

    const isViewer = useUserRole('manager');
    const history = useHistory();

    const [ flagged, setFlagged ] = useState(false);
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortHow, setSortHow] = useState('byDateByDomain');
    const pageSizes = [ 20, 50, 100 ];
    const [ pageSize, setPageSize ] = useState(pageSizes[0]);

    const orderedReports = reports; // injectBreakPoints(reports.sort(sorters[sortHow].func), sorters[sortHow].field);

    const updateReport = (_id, field, value) => {
        mongo.db('cart').collection('reports').updateOne({ _id }, { $set: { [field]: value } }).then((r) => {
            setReports((reports) => reports.map((r) => `${r.id}` === `${_id}` ? ({ ...r, [field]: value }) : r));
        });
    };

    useEffect(() => {
        if (!isViewer) {
            return;
        }

        // console.log(new Date (), `fetching`)
        Promise.all([
            mongo.db('cart').collection('users').find({}, { projection: { realmId: 1, name: 1, email: 1 } }),
            mongo.db('cart').collection('reports').find({ submit: true }, { sort: { _id: -1 }, projection: {
                realmId: 1,
                score: 1,
                showId: 1,
                type: 1,
                tsReported: 1,
                url: 1,
                reporterEmail: 1,
            } }),
        ]).then(async ([ users, reports ]) => {
            // console.log(new Date (), `fetched`);

            setReports(reports.map((report) => ({
                id: report._id,
                ...report,
                score: report.score,
                updateReport,
                reporter: users.find((u) => u.realmId === report.realmId) || { name: 'n/a', email: report.reporterEmail || 'n/a' },
            })));
            setLoading(false);
        });

        // Realm rules take care of this :)
    }, [isViewer]);

    return (<>
        <Switch>
            <Route path="/reports/view/:id" render={({ match }) => <AbuseReport match={match} updateReport={updateReport}/>}/>
            <Route>
                <Box display='flex' justifyContent='space-between' mb={1}>
                    <Typography variant="h4">All Reports</Typography>
                    <FormControlLabel control={<Toggle
                        checked={flagged}
                        onChange={() => setFlagged((flagged) => !flagged)}
                        color='error'
                    />} label="Flagged Only" />
                </Box>

                {!loading && <DataGrid
                    autoHeight
                    rows={flagged ? reports.filter((r) => r.score < 0) : reports}
                    columns={user.customData.type === 'admin' ? columns : columns.slice(1)}
                    pageSize={pageSize}
                    rowsPerPageOptions={pageSizes}
                    onPageSizeChange={(n) => setPageSize(n)}
                    disableSelectionOnClick
                    onRowClick={(params) => {
                        history.push(`/reports/view/${params.id.toString()}`)
                    }}
                />}

                {/*<Grid container spacing={2} alignItems="stretch">
                    {isViewer && !loading && reports.length === 0 && "No abuse reports found."}
                    {isViewer && !loading && reports.length > 0 && orderedReports.map((r) => <Grid key={r.label || r._id} item xs={6}>{r.label ? <BreakPointCard breakpoint={r} /> : <AbuseReportSummary report={r} />}</Grid>)}
                </Grid>*/}

            </Route>
        </Switch>
    </>);
};

const AbuseReport = ({ history, location, match, updateReport }) => {
    const { user, mongo } = useContext(RealmContext);
    const [report, setReport] = useState();
    const [evidence, setEvidence] = useState();
    const id = match.params.id;

    // const setFlagged = useCallback(() => {
    //     mongo.db('cart').collection('reports').updateOne({ _id: new BSON.ObjectID (id) }, { $set: { flagged: !report.flagged } }).then(() => {
    //         setReport((r) => ({ ...r, flagged: !r.flagged }));
    //         updateReport(id, 'flagged', !report.flagged);
    //     });
    // }, [report]);

    const [ reportReferredBy, setReportReferredBy ] = useState('');
    const [ reportHeader, setReportHeader ] = useState('');
    const [ reporter, setReporter ] = useState({});

    useEffect(() => {
        mongo.db('cart').collection('reports').findOne({ _id: new BSON.ObjectID (id) }).then(async (r) => {
            const reporter = await mongo.db('cart').collection('users').findOne({ realmId: r.realmId });
            if (reporter) {
                const accountOrg = {
                    reporter: reporter.reporterOrg,
                    registrar: reporter.registrarName,
                    registry: reporter.registryName,
                    admin: reporter.registryName || reporter.registrarName || reporter.reporterOrg,
                }[reporter.type] || '';
                setReporter(reporter || {});

                const settings = await mongo.db('cart').collection('settings').findOne({ type: reporter.type, name: accountOrg });
                setReportReferredBy(settings?.reportReferredBy);
                setReportHeader(settings?.reportHeader);
            }
            else {
                setReporter({ name: 'n/a', email: r.reporterEmail || 'n/a' });
            }
            setReport(r);
            downloadEvidence(r.attachments || []).then((files) => setEvidence(files));
        });
    }, [id]);

    return (<>
        <Box mt={1} mb={1} display='flex' justifyContent='space-between'>
            <Button component={Link} to="/reports">&lt; Back to All Reports</Button>
            {/* {report && <Button variant="contained" color={report.flagged ? 'success' : 'error'} onClick={setFlagged}>
                {report.flagged ? "Unflag Report" : "Flag Report"}
            </Button>} */}
        </Box>
        {report && <>
            <Grid container spacing={2} columns={16}>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abusive URL</Typography>
                    <Typography variant='h5'>{report.url}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Type</Typography>
                            <Typography variant='h6'>{abuseNames[report.type]}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Date</Typography>
                            <Typography variant='h6'>{report.date.date.toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Ongoing</Typography>
                            <Typography variant='h6'>{report.date.ongoing ? 'Yes' : 'No'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Report ID</Typography>
                    <Typography fontSize='smaller'>{report.showId}</Typography>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Reported At</Typography>
                    <Typography fontSize='smaller'>{report.tsReported.toLocaleString()}</Typography>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Reported By</Typography>
                    <Typography fontSize='smaller'>{reporter.name}</Typography>
                    <Typography fontSize='smaller'>{reporter.email}</Typography>
                    {reportReferredBy && <Typography fontSize='smaller'>{reportReferredBy}</Typography>}
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                {reportHeader && <Grid item xs={12}>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Reporter Header</Typography>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{reportHeader}</Typography>
                </Grid>}

                <Grid item xs={12}>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Abuse Description</Typography>
                    <Typography>{report.description}</Typography>
                </Grid>

                {report.type === 'phishing' && <>
                    <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Phishing Target</Typography>
                        <Typography>{report.target}</Typography>
                    </Grid>

                    {report.email && <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Received From</Typography>
                        <Typography>{report.email}</Typography>
                    </Grid>}
                </>}

                {report.type === 'malware' && <>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Suspicious Filename</Typography>
                        <Typography>{report.filename}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Malware Name</Typography>
                        <Typography>{report.malware}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>MD5 Checksum</Typography>
                        <Typography>{report.md5}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>SHA1 Checksum</Typography>
                        <Typography>{report.sha1}</Typography>
                    </Grid>
                </>}

                {report.type === 'spam' && <>
                    <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Received From</Typography>
                        <Typography>{report.email}</Typography>
                    </Grid>
                </>}

                {report.type === 'botnets' && <>
                    <Grid item xs>
                        <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Botnet Name</Typography>
                        <Typography>{report.botnet}</Typography>
                    </Grid>
                </>}
            </Grid>

            {(report.headers || report.body) && <Grid container spacing={2}>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Message Headers</Typography>
                    <TextField multiline readonly fullWidth inputProps={{style: { fontSize: '10pt'}}} rows={10} value={report.headers}/>
                </Grid>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Message Body</Typography>
                    <TextField multiline readonly fullWidth inputProps={{style: { fontSize: '10pt'}}} rows={10} value={report.body}/>
                </Grid>
            </Grid>}

            {evidence && <Grid container spacing={2}>
                <Grid item xs>
                    <Typography color='gray' variant='h6' sx={{fontVariant: 'all-small-caps'}}>Supporting Evidence ({evidence.length} file{evidence.length === 1 ? '' : 's'})</Typography>
                    <ImageList cols={4} rowHeight={256}>
                        {evidence.map((file) => file.type !== "application/pdf" && (<ImageListItem key={file.key}><img loading="lazy" src={file.url} /></ImageListItem>))}
                    </ImageList>
                    {evidence.map((file) => file.type === "application/pdf" && (
                        <Box sx={{ mb: 2 }}>
                            <a href={file.url} download={file.name}>
                            {file.name}
                            </a>
                        </Box>
                        )
                    )}
                </Grid>
            </Grid>}
        </>}
        {/* <xmp>{JSON.stringify(report, null, 4)}</xmp> */}
    </>);
};